.heading {
  /* text-align: center; */
  color: white;
  font-size: 60px;
}

#text {
  color: white;
  font-size: 35px;
  text-align: justify;
  /* margin-right: 100px; */
  /* margin-left: 100px; */
}

#sub {
  color: black;
  background-color: white;
  border-radius: 50px;
  font-size: 25px;
  padding: 15px 32px;
  border: none;
}
