/* .logo{
    width: 200px;
    height: 100px;
    left: 0px;
    top: 100px;;
    background-color: white;
    border-radius: 0px 50px 50px 0px;
    float : left ;
}

.logo_{
    height:50px; 
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.Nav {
    float : right;
    margin-top: 25px ;
    margin-right : 80px;
    font-size: 25px;
    color : white;
}
a:link {
    text-decoration: none;
    color : white;
  }
  
  a:visited {
    text-decoration: none;
    color : white;
  }
  
  a:hover {
    text-decoration: none;
    color : black;
  }
  
  a:active {
    color : black;
  } */

.tja-header-container {
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  box-shadow: none;
}

.tja-header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
}

.tja-header-col-1 {
  position: relative;
  left: -25px;
  background-color: white;
  border-radius: 0px 80px 80px 0px;
  box-shadow: 0px 5px 100px rgba(0, 0, 0, 0.2);
  padding: 20px 35px 20px 20px;
}

.tja-header-trans {
  position: relative;
  left: -25px;
  background-color: transparent;
  border-radius: 0px;

  padding: 20px 35px 20px 20px;
}

.tja-header-col-2 > ul {
  display: flex;
  list-style: none;
  color: white;
  font-size: 22px;
}

.tja-header-col-2 li {
  padding-left: 15px;
}

.tja-header-fixed {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9999;
  background: white;
  transition: all 0.5s ease;
}
